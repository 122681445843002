<template>
    <el-dialog :title="title" width="60%" :close-on-click-modal="false" :visible.sync="visible">
        <el-form size="small" :model="requiredForm" ref="requiredForm" label-width="140px" @keyup.enter.native="doSubmit()"
                 v-loading="loading" :disabled="method==='view'" @submit.native.prevent>
            <el-form-item label="城市：" prop="cityId" :rules="[{ required: true, message: '请选择城市', trigger: 'change' }]">
                <el-cascader v-model="requiredForm.cityId"
                             :options="cityOpt" :props="{ value: 'id', label: 'name' }" @change="changeCity"
                             style="width: 100%"></el-cascader>
            </el-form-item>
            <el-form-item label="医院：" prop="hospitalId"
                          :rules="[{ required: true, message: '请选择医院', trigger: 'change' }]">
                <el-select v-model="requiredForm.hospitalId" placeholder="请选择"  @change="changeHospital" style="width: 100%">
                    <el-option
                            v-for="item in hospitalOpt"
                            :key="item.id"
                            :label="item.hospitalName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="科室：" prop="departmentId"
                          :rules="[{ required: true, message: '请选择科室', trigger: 'change' }]">
                <el-select v-model="requiredForm.departmentId" placeholder="请选择" style="width: 100%">
                    <el-option
                            v-for="item in departmentOpt"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标配项名称：" prop="requiredName"
                          :rules="[{ required: true, message: '请输入标配项名称', trigger: 'blur' }]">
                <el-input v-model.trim="requiredForm.requiredName" placeholder="请输入标配项名称（必填项）" clearable maxlength="50"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">关闭</el-button>
      <el-button size="small" type="primary" v-if="method != 'view'" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                loading: false,
                title: '',
                method: '',
                requiredForm: {
                    id: '',
                    cityId: ['1277876432646750210', '738049426362204160'],
                    hospitalId: '',
                    departmentId: '',
                    requiredName: '',
                },
                cityOpt: [],
                hospitalOpt: [],
                departmentOpt: [],
            }
        },
        methods: {
            // 初始化数据
            init(method, row) {
                this.getCity()
                this.method = method
                if (method === 'add') {
                    this.title = `新建`
                } else if (method === 'edit') {
                    this.title = '修改'
                } else if (method === 'view') {
                    this.title = '查看'
                }
                this.visible = true
                this.$nextTick(() => {
                    if (method != 'add') {
                        this.recover(this.requiredForm, row);
                    } else {
                        this.requiredForm.id = ''
                        this.$refs.requiredForm.resetFields();
                    }
                })
            },
            // 选择城市
            changeCity() {
                this.requiredForm.hospitalId = ''
                this.getHospital()
            },
            // 选择医院
            changeHospital() {
                this.requiredForm.departmentId = ''
                this.getDepartment()
            },
            // 获取城市
            getCity() {
                this.$axios(this.api.order.getSysArea).then(res => {
                    if (res.status) {
                        this.cityOpt = res.data
                        this.cityOpt[0].children.map(item => {
                            if (!item.children.length) {
                                delete item.children;
                            }
                        })
                        this.getHospital()
                    }
                })
            },
            // 获取医院
            getHospital() {
                this.$axios(this.api.order.getMingenHospitalByCityId, {cityId: this.requiredForm.cityId[1]}).then(res => {
                    if (res.status) {
                        this.hospitalOpt = res.data
                        this.getDepartment()
                    }
                })
            },
            // 获取科室
            getDepartment() {
                this.$axios(this.api.order.getAllMingenDepartment, {hospitalId: this.requiredForm.hospitalId}).then(res => {
                    if (res.status) {
                        this.departmentOpt = res.data
                    }
                })
            },
            // 表单提交
            doSubmit() {
                this.$refs['requiredForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if (this.method === 'add') {  // 新增
                            this.$axios(this.api.order.requiredSave, this.requiredForm, 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message.success(data.msg)
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                } else {
                                    this.$message.error(data.msg)
                                }
                            })
                        } else if (this.method === 'edit') {  // 修改
                            this.$axios(this.api.order.requiredUpdate, this.requiredForm, 'put').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.visible = false
                                    this.$message.success(data.msg)
                                    this.$emit('refreshDataList')
                                } else {
                                    this.$message.error(data.msg)
                                }
                            })
                        }
                    }
                })
            },
        }
    }
</script>
